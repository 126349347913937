import enUS from './en-us.json'
import esUS from './es-us.json'
import enIE from './en-ie.json'
import ptPT from './pt-pt.json'
import enIN from './en-in.json'
import esES from './es-es.json'
import nlBE from './nl-be.json'
import frBE from './fr-be.json'
import itIT from './it-it.json'
import nlNL from './nl-nl.json'
import nbNO from './nb-no.json'
import fiFI from './fi-fi.json'
import svSE from './sv-se.json'
import enSG from './en-sg.json'
import daDK from './da-dk.json'
import enAU from './en-au.json'
import enNZ from './en-nz.json'
import enGB from './en-gb.json'
import deDE from './de-de.json'
import deCH from './de-ch.json'
import frCH from './fr-ch.json'
import itCH from './it-ch.json'
import deAT from './de-at.json'
import frFR from './fr-fr.json'
import enCA from './en-ca.json'
import frCA from './fr-ca.json'

const locales = {
  'en-us': enUS,
  'es-us': esUS,
  'en-ie': enIE,
  'pt-pt': ptPT,
  'en-in': enIN,
  'es-es': esES,
  'nl-be': nlBE,
  'fr-be': frBE,
  'it-it': itIT,
  'nl-nl': nlNL,
  'nb-no': nbNO,
  'fi-fi': fiFI,
  'sv-se': svSE,
  'en-sg': enSG,
  'da-dk': daDK,
  'en-au': enAU,
  'en-nz': enNZ,
  'en-gb': enGB,
  'de-de': deDE,
  'de-ch': deCH,
  'fr-ch': frCH,
  'it-ch': itCH,
  'de-at': deAT,
  'fr-fr': frFR,
  'en-ca': enCA,
  'fr-ca': frCA
}

export default locales
