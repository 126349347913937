import * as React from 'react'

export const useOpenAllFaqs = (faqIdString = 'FAQ', listLength = 0) => {
  let i = 0
  const [expandedCollapsibles, setExpandedCollapsibles] = React.useState({
    [`${faqIdString} #1`]: true,
  })

  React.useEffect(() => {
    let obj = {}
    while (i <= listLength) {
      i++
      obj = {
        ...obj,
        [`${faqIdString} #${i}`]: true,
      }
    }
    setExpandedCollapsibles({ ...obj })
  }, [i, listLength, faqIdString])

  return expandedCollapsibles
}
