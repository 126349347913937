import { BoundedContent, Column, GridContainer, H3, Row, SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { FaqSection } from './components/FaqSection'
import localizations from '../localizations/locales/index'
import SpecialOffersDetail from './components/SpecialOffersDetail'
import { ContentfulContextProvider, DebugSection, ContentfulLink } from '@vp/mcs-react-tools'

export interface FragmentProps {
  faqLabel: string;
  faqList: [
    {
      answer: string;
      contentful_id: string;
      question: string
    }
  ],
  contentLabel: string;
  locale: string;
  content: string;
  contentfulMetadata: any;
  debugData: any;
}

export const Fragment = ({ faqLabel, faqList, contentLabel, locale, content, contentfulMetadata, debugData }: FragmentProps) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.accordion,
  ])

  const sectionId = localizations[locale]['offerDetailsSection']

  const search = typeof window !== 'undefined' ? window.location.search : ''

  return (
    <ContentfulContextProvider space={contentfulMetadata?.spaceId || ''}>
      <DebugSection
        contentfulMetadata={contentfulMetadata}
        availability={debugData}
        search={search}
      />
      <ContentfulLink id={contentfulMetadata?.contentful_id} />
      <BoundedContent paddingX={0}>
        <GridContainer>
          <Row marginTop={{ xs: 7, md: 0 }} marginBottom={{ xs: 7, md: 9 }} backgroundColor='warm-white'>
            <FaqSection label={faqLabel} list={faqList} />
          </Row>
          {contentLabel && (
            <Row>
              <Column span={8} push={2} paddingX={{ xs: 5 }}>
                <H3
                  textAlign='left'
                  marginBottom={0}
                  fontSize='large'
                  fontWeight='bold'
                  data-elementname='Offer Details Section'
                  id={sectionId}
                >
                  {contentLabel}
                </H3>
                <SpecialOffersDetail content={content} />
              </Column>
            </Row>
          )}
        </GridContainer>
      </BoundedContent>
    </ContentfulContextProvider>
  )
}
