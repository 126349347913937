import React from 'react'
import parse from 'html-react-parser'
import { UncustomizableMarkup } from '@vp/swan'

interface SpecialOffersDetailProps {
  content: string | null;
}
const SpecialOffersDetail = React.forwardRef<HTMLDivElement, SpecialOffersDetailProps>((props) => {
  const { content } = props

  return (
    <UncustomizableMarkup>
      {content ? parse(content) : null}
    </UncustomizableMarkup>
  )
})

export default SpecialOffersDetail
